.reveal {
  outline: none;
  padding: 3em;

  .close-button {
    position: absolute;
    z-index: 10;
    color: rgba($font-color, 0.5);
    background-color: transparent;
    cursor: pointer;
    right: 1rem;
    top: 0.5rem;
    font-size: 3em;
    line-height: 1;
  }

  .twitter-tweet {
    margin: 0 auto;
    padding: 20px;
  }
}
