.cc-references__detail {
  position: relative;

  &-copy {
    margin-bottom: 32px;

    @include breakpoint(medium) {
      margin-bottom: 64px;
    }
  }

  &-content {
    margin-bottom: 40px;
  }

  .cc-video-container {
    margin: 16px 0;
  }

  &-picture {
    display: block;
    margin: 16px 0;

    img {
      display: block;
      width: 100%;
    }
  }
}
