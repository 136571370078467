.cc-navigation {
  display: none;
  opacity: 0;
  align-items: center;
  justify-content: center;

  @include breakpoint(large) {
    display: flex;
    opacity: 1;
  }

  .open & {
    position: fixed;
    width: 100vw;
    overflow: auto;
    left: 0;
    top: $header-height-mobile;
    background: url($image-dir + '/bg-paper-tile.png') repeat;
    display: flex;
    justify-content: center;
    opacity: 1;

    @include breakpoint(medium) {
      top: $header-height;
    }

    @include breakpoint(large) {
      position: relative;
      display: flex;
      width: auto;
      height: auto;
      left: auto;
      top: auto;
      background: none;
      overflow: initial;
    }
  }

  &__content {
    width: 100%;

    .open & {
      padding-top: 20px;
      height: calc(100vh - #{$header-height-mobile * 2});
      opacity: 1;

      @include breakpoint(medium) {
        padding-top: 0;
        height: calc(100vh - #{$header-height * 2});
      }

      @include breakpoint(large) {
        display: block;
        height: auto;
      }
    }
  }

  &__container {
    margin: 18px auto 10px auto;
    display: flex;
    align-items: center;
    width: calc(100% - #{($corner-logo-width-mobile + $corner-logo-space-mobile) * 2});

    @include breakpoint(medium) {
      margin-bottom: 23px;
      margin-top: 66px;
      width: calc(100% - #{($corner-logo-width + $corner-logo-space) * 2});
    }

    &::after,
    &::before {
      content: '';
      flex: 1 0 auto;
      display: block;
      height: $frame-border-width;
      background-color: $primary-color;
      margin: 0 $frame-border-space;

      @include breakpoint(large) {
        margin: 0 32px 0 $frame-border-space;
      }
    }

    &::after {
      margin: 0 $frame-border-space;

      @include breakpoint(large) {
        margin: 0 $frame-border-space 0 32px;
      }
    }

    &--inner {
      display: block;
      margin: 0 auto;

      @include breakpoint(large) {
        width: 75vw;
        max-width: 800px;
      }

      &.open {
        &::after {
          content: '';
          position: fixed;
          left: 0;
          bottom: 0;
          height: $header-height-mobile;
          width: 100%;
          background: url($image-dir + '/bg-paper-tile.png') repeat;
          z-index: $z-index-header;

          @include breakpoint(medium) {
            height: $header-height;
          }

          @include breakpoint(large) {
            display: none;
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .open & {
      flex-direction: column;
      width: auto;
      align-items: center;

      @include breakpoint(large) {
        align-items: stretch;
        flex-direction: row;
        width: 100%;
      }
    }

    &--item {
      list-style: none;
      display: flex;
      margin-right: 10px;
      white-space: nowrap;

      &-link {
        font-family: $BaseFontBold;
        font-size: rem(18px);
        line-height: rem(20px);
      }

      &:last-child {
        margin-right: 0;
      }

      .open & {
        margin-bottom: 40px;

        @include breakpoint(large) {
          margin-bottom: 0;
        }

        .cc-navigation__list--item-link {
          font-size: rem(24px);
          line-height: rem(27px);

          @include breakpoint(large) {
            font-size: rem(18px);
            line-height: rem(20px);
          }
        }
      }

      &-social {
        .open & {
          flex-direction: column;

          @include breakpoint(large) {
            flex-direction: row;
          }
        }
      }
    }

    &--headline {
      display: block;
      margin-right: 10px;
      font-family: $BaseFontBold;
      font-size: rem(18px);
      line-height: rem(20px);

      .open & {
        font-size: rem(24px);
        line-height: rem(27px);
        margin: 50% 0 10px 0;

        @include breakpoint(large) {
          font-size: rem(18px);
          line-height: rem(20px);
          margin: 0 10px 0 0;
        }
      }
    }

    &--social {
      display: flex;
      align-items: center;
      margin-top: -2px; // the font has some white space ...

      .cc-navigation__list--item {
        margin-right: 7px;
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }

        &-link {
          > span {
            @include visually-hidden();
          }
        }
      }

      .open & {
        justify-content: center;

        @include breakpoint(large) {
          justify-content: flex-start;
        }
      }

      .cc-icon {
        width: rem($icon-width-mobile);
        height: rem($icon-height-mobile);

        @include breakpoint(large) {
          width: rem($icon-width);
          height: rem($icon-height);
        }
      }
    }
  }

  &__toggle {
    width: 20px;
    height: 22px;
    cursor: pointer;

    @include breakpoint(large) {
      display: none;
    }

    > div {
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      width: 20px;
      height: 15px;
      transform: translate(-50%,-50%);
      margin-top: 5px;

      &:before,
      &:after {
        background-color: #000;
        content: '';
        display: block;
        height: 2px;
        transition: all 200ms ease-in-out;
      }

      &:before {
        box-shadow: 0 5px 0 #000;
        margin-bottom: 8px;
      }

      .open & {
        &:before {
          box-shadow: 0 0 0 #000;
          transform: translateY(5px) rotate(45deg);
        }

        &:after {
          transform: translateY(-5px) rotate(-45deg);
        }
      }

      @include breakpoint(medium) {
        margin-top: 23px;
      }

      @include breakpoint(large) {
        &:before,
        &:after {
          display: none;
        }
      }

      > span {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
      }
    }
  }

  &__language-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    @include breakpoint(large) {
      position: fixed;
      top: calc(#{$corner-logo-space} - 14px - 7px); // 14px is height
      right: calc(#{$corner-logo-space} + #{$corner-logo-width / 2} - (46px / 2)); // 46px is width
    }

    .cc-navigation__list--item {
      font-family: $BaseFont;
      font-size: rem(14px);
      line-height: 1;
      position: relative;
      margin-bottom: 0;


      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        right: -6px;
        top: -1px;
        background-color: $font-color;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      .cc-navigation__language-switch--active {
        font-family: $BaseFontBold;
        text-decoration: underline;
        margin-top: 2px;
      }
    }
  }

  &__footer-links {
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(large) {
      display: none;
    }
  }
}
