/**
 * Visually hidden
 * ========================================================================= */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
@mixin visually-hidden($reset: false) {
  @if not $reset {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  } @else {
    position: relative;
    width: auto;
    height: auto;
    margin: auto;
    padding: inherit;
    border: inherit;
    overflow: inherit;
    clip: auto;
  }
}
