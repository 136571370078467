.cc-link {
  &-prev,
  &-next {
    font-family: $BaseFont;
    font-size: rem(16px);
    line-height: rem(16px);
    margin-bottom: 48px;
    display: inline-block;

    .cc-icon {
      margin-top: -3px;
    }
  }
}
