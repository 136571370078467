.cc-slider {
  position: relative;
  margin: 0 30px $section-bottom-mobile 30px;

  @include breakpoint(medium) {
    margin: 0 30px $section-bottom 30px;
  }

  .cc-h3 {
    font-size: rem(29px);
    line-height: rem(32px);

    @include breakpoint(large) {
      font-size: rem(61px);
      line-height: rem(72px);
    }

    &::after {
      position: relative;
      transform: translateX(-50%);
      left: 50%;
    }
  }

  &__content {
    text-align: center;
  }

  .slick-arrow {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    width: 12px;
    height: 18px;
    background: none;
    color: $primary-color;
    cursor: pointer;

    @include breakpoint(medium) {
      width: 35px;
      height: 35px;
    }

    &:hover {
      .cc-icon {
        transform: scale(1.2);
      }
    }

    .cc-icon {
      position: absolute;
      transition: transform .2s ease-in-out;
      transform-origin: 50px 50px;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }

  &__prev {
    left: -15px;

    @include breakpoint(medium) {
      left: -45px;
    }
  }

  &__next {
    right: -15px;

    @include breakpoint(medium) {
      right: -45px;
    }
  }

  &__subline {
    font-size: rem(16px);
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
