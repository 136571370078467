.cc-footer {
  margin-bottom: 30px;

  &__list {
    display: flex;
    list-style: none;
    margin: 0 auto;

    &--item {
      text-transform: uppercase;

      &:last-child {
        .cc-footer__list--link {
          &::after {
            display: none;
          }
        }
      }
    }

    &--link {
      font-size: rem(12px);
      margin: 0 5px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        right: -5px;
        top: -1px;
        background-color: $font-color;
      }
    }
  }
}
