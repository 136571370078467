// Border Frame with Logo
$corner-logo-space-mobile: 8px;
$corner-logo-space: 40px;
$corner-logo-width-mobile: 42px;
$corner-logo-height-mobile: 42px;
$corner-logo-width: 72px;
$corner-logo-height: 72px;

$frame-border-space: 8px;
$frame-border-width: 4px;

$section-bottom-mobile: 100px;
$section-bottom: 200px;

$header-height: 108px;
$header-height-mobile: 48px;

$top-spacing-mobile: 32px;
$top-spacing: 24px;
