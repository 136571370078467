.cc-text {
  $content-space-mobile: 20px;
  $content-space: 48px;

  margin-bottom: $section-bottom-mobile - $content-space-mobile;

  @include breakpoint(medium) {
    margin-bottom: $section-bottom - $content-space;
  }

  &__content {
    margin-bottom: $content-space-mobile;

    @include breakpoint(medium) {
      margin-bottom: $content-space;
    }
  }

  &__teaser {
    margin: 10px 0 15px 0;
    position: relative;

    .cc-h3 {
      font-family: $ScrawnyCatFont;
      font-size: rem(38px);
      line-height: rem(32px);
      text-align: center;
      font-weight: normal;
      position: relative;
      z-index: 1;
      transition: transform 0.2s ease-in-out;
      text-transform: uppercase;

      @include breakpoint(medium) {
        font-size: rem(51px);
        line-height: rem(40px);
      }

      &:after {
        display: none;
      }
    }

    &:hover {
      .cc-h3 {
        transform: scale(1.1);
      }
    }

    &--arrow-down,
    &--arrow-up {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      width: 23px;
      height: 61px;

      @include breakpoint(medium) {
        width: 33px;
        height: 71px;
      }
    }

    &--arrow-up {
      bottom: -35px;
    }

    &--arrow-down {
      top: -20px;

      @include breakpoint(medium) {
        top: -10px;
      }
    }

    &--arrow-container {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      &--bottom {
        top: auto;
        bottom: 0;
      }

      &::after,
      &::before {
        content: '';
        position: absolute;
        height: $frame-border-width;
        left: 0;
        top: 0;
        width: 40%;
        background-color: $primary-color;
      }

      &::after {
        left: auto;
        right: 0;
      }
    }

    &--frame {
      position: relative;
      min-height: 260px;
      display: flex;
      flex-direction: column;

      @include breakpoint(medium) {
        min-height: 340px;
      }

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: $frame-border-width;
        top: 0;
        bottom: 0;
        background-color: $primary-color;
      }

      &::after {
        right: 0;
      }
    }

    &--background {
      position: relative;
      flex: 1 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      &::after {
        content: '';
        position: absolute;
        transform: translateX(-50%);
        top: 0;
        width: 100%;
        height: 100%;
        left: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url($image-dir + '/referenzen-brushes.png');
        background-size: 306px 227px;

        @include breakpoint(medium) {
          background-image: url($image-dir + '/referenzen-brushes@2x.png');
        }
      }
    }
  }

  &__space-bottom {
    margin-bottom: 20px;
  }
}
