* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: $BaseFont;
}

html,
body {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  /**
  * 1. box-sizing is set to border-box for the html element
  */
  *,
  *:before,
  *:after {
    box-sizing: inherit; /* 1 */
  }

  /**
  * Placeholder. Remove opacity in Firefox.
  */
  ::placeholder {
    opacity: 1;
  }

  /**
  * The ::-ms-clear CSS pseudo-element represents a button (the "clear button") at the edge of
  * a text <input> which clears away the current value of the <input> element. This button and
  * pseudo-element are non-standard, supported only in Internet Explorer 10 and 11 and Edge 12+,
  * hence the vendor prefix (`-ms` for Microsoft). The clear button is only shown on focused,
  * non-empty text <input> elements.
  */
  ::-ms-clear {
    display: none;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  main {
    display: block;
    max-width: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  figure {
    margin-right: 0;
    margin-left: 0;
  }

  /**
  * !important makes the hidden attribute behave the way we expect it to do
  */
  [hidden] {
    display: none !important;
  }
}

body {
  background: url($image-dir + '/bg-paper-tile.png') repeat;
}

.cc-content__start {
  width: 100%;
  margin-top: $header-height-mobile + $top-spacing-mobile;

  @include breakpoint(medium) {
    margin-top: $header-height + $top-spacing;
  }
}

.cc-container__start {
  position: relative;
}

.cc-list {
  margin-left: 15px;
}
