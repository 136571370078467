@font-face {
	font-family: 'manus-webfont';
	src: url('#{$font-dir}/manus-webfont.woff2') format('woff2'),
			 url('#{$font-dir}/manus-webfont.woff') format('woff');
	font-weight: normal;
  font-style: normal;
  font-display: swap
}

@font-face {
	font-family: 'ScrawnyCat';
	src: url('#{$font-dir}/3A1D5C_0_0.woff2') format('woff2'),
			 url('#{$font-dir}/3A1D5C_0_0.woff') format('woff');
	font-weight: normal;
  font-style: normal;
  font-display: swap
}

@font-face {
	font-family: 'SofiaPro-Regular';
	src: url('#{$font-dir}/3A1D5C_1_0.woff2') format('woff2'),
			 url('#{$font-dir}/3A1D5C_1_0.woff') format('woff');
	font-weight: normal;
  font-style: normal;
  font-display: swap
}

@font-face {
	font-family: 'SofiaPro-Bold';
	src: url('#{$font-dir}/3A2369_0_0.woff2') format('woff2'),
			 url('#{$font-dir}/3A2369_0_0.woff') format('woff');
	font-weight: normal;
  font-style: normal;
  font-display: swap
}


h1, h2, h3, h4 {
	font-family: $BaseFont;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
}

.cc-h1 {
  font-family: $ScrawnyCatFont;
  font-size: rem(120px);
  line-height: rem(124px);
  margin-bottom: rem(80px);
  position: relative;
  display: inline-block;
}

.cc-h2,
.cc-h1-sub {
  font-family: $ScrawnyCatFont;
  font-size: rem(57px);
  line-height: rem(54px);
  margin-bottom: rem(80px);
  padding: 0 rem(20px);
  position: relative;
  display: inline-block;

  @include breakpoint(large) {
    font-size: rem(120px);
    line-height: rem(124px);
  }

  > span {
    font: inherit;
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    z-index: 0;
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% - 5px);
    left: rem(-6px);
    top: rem(-3px);
    box-sizing: border-box;
    border-style: solid;
    border-width: 10px;
    border-image: url($image-dir + '/brush-headline.png') 18 fill stretch;

    @include breakpoint(large) {
      height: calc(100% + 6px);
      top: rem(-6px);
      border-image: url($image-dir + '/brush-headline@2x.png') 18 fill stretch;
    }
  }
}

.cc-h1-sub {
  font-size: rem(38px);
  line-height: rem(32px);
  margin-bottom: rem(29px);
  text-align: center;

  @include breakpoint(medium) {
    font-size: rem(51px);
    line-height: rem(40px);
    text-align: left;
  }
}

.cc-h1-detail {
  font-family: $BaseFontBold;
  font-size: rem(34px);
  line-height: rem(40px);
  margin-bottom: rem(32px);

  @include breakpoint(medium) {
    font-size: rem(54px);
    line-height: rem(64px);
  }
}

body {
  counter-reset: sub-h2;
}

.cc-h2-sub {
  font-family: $BaseFontBold;
  font-size: rem(24px);
  line-height: rem(24px);
  margin-bottom: rem(16px);

  &::before{
    counter-increment: sub-h2;
    content: counter(sub-h2) ". ";
  }
}

.cc-h3-sub {
  font-family: $BaseFontBold;
  font-size: rem(18px);
  line-height: rem(32px);
}

.cc-h3 {
  font-family: $BaseFontBold;
  font-size: rem(24px);
  line-height: rem(26px);
  margin-bottom: rem(24px);

  &::after {
    content: '';
    display: block;
    width: 40px;
    height: 4px;
    background-color: $font-color;
    margin-top: rem(8px);
  }
}

.cc-link {
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    width: 100%;
    height: 4px;
    background-color: $primary-color;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

p, li {
	font-size: rem(17px);
	line-height: rem(28px);
}

strong,
b {
  font-family: $BaseFontBold;
	font-weight: bold;
}

a {
  color: $font-color;
  text-decoration: none;
}
