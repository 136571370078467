.cc-intro {
  $contentwidth-mobile: 270px;
  $contentwidth: 441px;

  margin-bottom: rem(60px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 rem(60px);

  .cc-h1 {
    line-height: rem(100px);
    margin: rem(60px) 0 0 0;
    position: relative;
    z-index: 1;
  }

  span {
    @include visually-hidden;
  }

  &__we-are {
    width: 186px;
    height: 211px;
    margin-bottom: rem(10px);

    @include breakpoint(medium) {
      width: 315px;
      height: 359px;
      margin-bottom: rem(20px);
    }
  }

  &__text {
    font-family: $ManusFont;
    font-size: rem(28px);
    line-height: rem(35px);
    position: relative;
    z-index: 1;
  }

  &__content {
    position: relative;
    min-width: $contentwidth-mobile;
    margin-top: -80px;

    @include breakpoint(medium) {
      margin-top: 0;
      min-width: $contentwidth;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      transform: translateX(-50%);
      top: 0;
      left: 50%;
      width: $contentwidth-mobile;
      height: 335px;
      background-repeat: no-repeat;
      background-position: center;

      @include breakpoint(medium) {
        top: -30px;
        width: $contentwidth;
        height: 566px;
      }
    }

    &::after {
      background-image: url($image-dir + '/hero-brush-1.png');
      background-size: 148px 280px;

      @include breakpoint(medium) {
        background-image: url($image-dir + '/hero-brush-1@2x.png');
        background-size: 295px 495px;
      }
    }

    &::before {
      background-image: url($image-dir + '/hero-brush-2.png');
      background-size: 120px 200px;

      @include breakpoint(medium) {
        background-image: url($image-dir + '/hero-brush-2@2x.png');
        background-size: 221px 351px;
      }
    }
  }
}
