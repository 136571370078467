.cc-intro-who {
  @include section-spacing;

  &__container {
    margin-bottom: 50px;

    @include breakpoint(large) {
      flex-wrap: nowrap;
      align-items: center;
    }
  }

  &__content {
    flex: 0 1 auto;
    max-width: none;
    text-align: left;
  }

  &__image {
    order: 0;
    flex: 1 0 50%;
    margin-bottom: 50px;

    @include breakpoint(large) {
      margin-bottom: 0;
      order: 1;
    }

    &--picture {
      width: 100%;

      @include breakpoint(large) {
        width: auto;
      }

      img {
        width: 100%;

        @include breakpoint(large) {
          width: auto;
        }

      }
    }
  }

  &__mila {
    order: 1;
    margin-bottom: 50px;

    @include breakpoint(large) {
      order: 0;
      margin-top: 40px;
      margin-bottom: 0;
    }
  }

  &__sophie {
    order: 2;

    @include breakpoint(large) {
      margin-top: -30px;
    }
  }
}
