.cc-references__overview {
  position: relative;

  &-teaser {
    margin-bottom: 40px;
  }

  &-copy {
    margin-bottom: 32px;

    @include breakpoint(medium) {
      margin-bottom: 64px;
    }
  }

  &-link {
    display: block;
    margin-bottom: 32px;

    @include breakpoint(medium) {
      transition: all .2s ease-out;
    }

    &:hover {
      @include breakpoint(medium) {
        transform: scale(1.03);
      }
    }
  }

  &-picture {
    display: block;

    img {
      display: block;
      width: 100%;
    }
  }
}
